import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"


import {
  Link,
  makeStyles,
  Typography,
  Box,
} from "@material-ui/core"


import ImageBillsHappenWebsite from '../images/billshappen-website.jpg'

const useStyles = makeStyles((theme) => ({
  list: {
    marginLeft: theme.spacing(3),
  },
  image: {
    width: '100%',
    maxWidth: '600px',
    height: 'auto',
    margin: '0 auto',
    display: 'block',
  },
}));



export default function IndexPage() {
  const classes = useStyles();
  return (
    <Layout
      subject="BillsHappen Informational Review (2020): Is it too good to be true?"
      topic="REVIEW"
    >
    <SEO title="BillsHappen Informational Review (2020): Is it too good to be true?" />

    <Box 
      paddingTop={8}
      paddingBottom={4}
    >
      <Typography 
        variant="body1" 
        paragraph
      >
        You’re watching TV and see a commercial for BillsHappen.com and realize you can use some extra cash. They say they can help you get cash quickly, but how do you know whether you can trust their advertisement?
      </Typography>
      <Typography 
        variant="body1" 
        paragraph
      >
        First off make sure you go directly to <Link href="https://billshappen.com/">BillsHappen Official As Seen On TV Website</Link>.  There are many imposters that will try to trick you to go to other sites by violating and infringing on their trademarked name BillsHappen&reg;! Their official website looks like this. 
      </Typography>
      <Box 
      component="img" 
      src={ImageBillsHappenWebsite} 
      className={classes.image}
      />
      <Typography 
        variant="body1" 
        paragraph
      >
        BillsHappen is an intelligent platform designed to help you find a personal loan when you need it. Through their expansive network of lenders, BillsHappen help people find a way to borrow the funds they need. BillsHappen is not a lender, it’s a platform just like a travel website.
      </Typography>
    </Box>
    

    <Box paddingY={4}>
      <Typography 
        variant="h2"
        gutterBottom
      >
        Things to know about BillsHappen:
      </Typography>
      <Typography 
        component="ol" 
        variant="body1" 
        paragraph
        className={classes.list}
      >
        <li>Who is BillsHappen?</li>
        <li>Is BillsHappen a scam?</li>
        <li>What can I use a BillsHappen personal loan for?</li>
        <li>How much money can I borrow through BillsHappen?</li>
        <li>Can I get approved for a loan with poor credit?</li>
        <li>How much does it cost to use BillsHappen?</li>
        <li>How does the loan process work?</li>
        <li>How long does it take to get a loan with BillsHappen?</li>
        <li>What if I don’t get matched to a BillsHappen lender?</li>
        <li>Is my information secure?</li>
      </Typography>
    </Box>


    <Box paddingY={4}>
      <Typography 
        variant="h3"
        gutterBottom
      >
        Who is BillsHappen?
      </Typography>
      <Typography 
        variant="body1" 
        paragraph
      >
        BillsHappen is a trademarked As Seen On TV online lending intelligent platform. They work with a variety of lenders to help connect regular people like you with services for personal loan financing. Started in 2018 BillsHappen helped over 250,000 people just like you to find some extra cash.
      </Typography>
    </Box>
    

    <Box paddingY={4}>
      <Typography 
        variant="h3"
        gutterBottom
      >
        Is BillsHappen for real?
      </Typography>
      <Typography 
        variant="body1" 
        paragraph
      >
      BillsHappen is a real website that can help you find personal loans. BillsHappen has hundreds of licensed lenders in their network.  Once you submit your information is sent to the lenders in their network for review.  Be patient this process could take 5 minutes while they are trying to find you a lender that fits your needs. They have over 200. If you are redirected to a secure lender it means that lender may be able to help. Read all the information before accepting your loan.
      </Typography>
    </Box>
    

    <Box paddingY={4}>
      <Typography 
        variant="h3"
        gutterBottom
      >
      What can I use a BillsHappen personal loan for?
      </Typography>
      <Typography 
        variant="body1" 
        paragraph
      >
      Its handy for a wide variety of things. Some of the most popular uses for <Link href="https://billshappen.com/">loans from BillsHappen</Link> lenders include:
      </Typography>
      <Typography 
        component="ul" 
        variant="body1" 
        className={classes.list}
        paragraph
      >
        <li>Paying for unexpected household expenses</li>
        <li>Getting by until payday</li>
        <li>Auto Repairs</li>
        <li>Unexpected medical bills</li>
        <li>Catching up on unpaid bills</li>
        <li>Emergencies</li>
      </Typography>
    </Box>
    



    <Box paddingY={4}>
      <Typography 
        variant="h3"
        gutterBottom
      >
      How much money can I borrow through BillsHappen?
      </Typography>
      <Typography 
        variant="body1" 
        paragraph
      >
      With BillsHappen, you can <Link href="https://billshappen.com/">apply for up to $5000 in personal loans</Link>. Since BillsHappen isn’t a lender, there are no guarantees about the loan amounts available. However, with BillsHappen you’re able to search for loans from $100 to $5000. You should only borrow the amount of money you need.
      </Typography>  
    </Box>
    

    <Box paddingY={4}>
      <Typography 
        variant="h3"
        gutterBottom
      >
      Can I get approved for a loan with poor credit?
      </Typography>
      <Typography 
        variant="body1" 
        paragraph
      >
      You could! BillsHappen works with lenders who understand that life happens.
      </Typography>
    </Box>
    

    <Box paddingY={4}>
      <Typography 
        variant="h3"
        gutterBottom
      >
      How much does it cost to use BillsHappen?
      </Typography>
      <Typography 
        variant="body1" 
        paragraph
      >
      BillsHappen is totally free to use! 
      </Typography>
    </Box>
    

    <Box paddingY={4}>
      <Typography 
        variant="h3"
        gutterBottom
      >
      How does the process work?
      </Typography>
      <Typography 
        variant="body1" 
        paragraph
      >
      Looking for a <Link href="https://billshappen.com/">personal loan with BillsHappen</Link> is simple:
      </Typography>
      <Typography 
        component="ul" 
        variant="body1" 
        className={classes.list}
        paragraph
      >
        <li>Fill out the online loan request form with the information required of you. </li>
        <li>Press submit.</li>
        <li>Wait for BillsHappen to connect you with lenders.</li>
      </Typography>
    </Box>
    


    <Box paddingY={4}>
      <Typography 
        variant="h3"
        gutterBottom
      >
      How long does it take to get a loan with BillsHappen?
      </Typography>
      <Typography 
        variant="body1" 
        paragraph
      >
      The <Link href="https://billshappen.com/">loan application process through BillsHappen</Link> is quick and easy. It only takes a few minutes to complete the form. If you match with a lender, you could have access to your funds in as little as 24 hours!
      </Typography>
    </Box>
    

    <Box paddingY={4}>
      <Typography 
        variant="h3"
        gutterBottom
      >
      What if I don’t get matched to a BillsHappen lender?
      </Typography>
      <Typography 
        variant="body1" 
        paragraph
      >
      Try again the next morning or in a few days.  Lenders have different business hours and BillsHappen is always adding new ones.
      </Typography>
    </Box>
    

    <Box paddingY={4}>
      <Typography 
        variant="h3"
        gutterBottom
      >
      Is my information secure?
      </Typography>
      <Typography 
        variant="body1" 
        paragraph
      >
      BillsHappen does not store any personal information.  Only the lender you’re matched to will have it to get you your loan.  
      </Typography>
    </Box>
  
  </Layout>
);
}
